export const environment = {
  production: true,
  APIEndpoint: 'https://api.consource.io/api/',
  systemBase: 'https://app.consource.io/',
  auth: {
    domain: 'consource-prod.eu.auth0.com',
    clientId: 'vcBTUg7tzK3Fl4wxAn6Y4NFhsrs009uM',
    audience: 'https://consource-prod.eu.auth0.com/api/v2/',
    redirectUri: 'https://app.consource.io/',
  },
  socketUrl: 'https://api.consource.io/',
  httpInterceptorUrl: 'https://api.consource.io/api/*',
  loadFromSandboxData: false,
};
